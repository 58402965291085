<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <top-title
        body="My Page에서 선생님의 웨비나 사전등록 내역, 관심 콘텐츠, 활동 내역을 조회하실 수 있으며, 회원정보도 수정하실 수 있습니다."
        header="My Page"
        type="05"/>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb">My Page</li>
            <li class="breadcrumb">{{getMenuName}}</li>
          </ul>
        </nav>
      </div>
      <!--Breadcrumb 영역 e-->

      <!--내용 영역 s-->
      <router-view/>
      <!--내용 영역 e-->

    </div>
  </main>
</template>

<script>
import TopTitle from "@/components/common/TopTitle";

export default {
  name: "index",
  components: {
    TopTitle,
  },
  computed:{
    getMenuName(){
      return this.$store.state.menu.menuList[this.$route.name].menuName
    }
  },

}
</script>

<style scoped>

</style>
